
import { ref, defineComponent } from 'vue'
import moment from 'moment-timezone'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')
import * as api from '../../utils/api'
import axios from 'axios'
import { useDialog } from 'naive-ui'
import { workTypes, prefectures, bussnessType } from '../../utils/const'

export default defineComponent({
	data() {
		const formRef = ref(null)
		const refProp = {
			name: '',
			furigana: '',
			addressDetails: '',
			tel: '',
			postcode: '',
			personName: '',
			personNameFurigana: '',
			type: '',
		}
		const modelRef = ref(refProp)
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		const bt = bussnessType()
		return {
			formRef,
			model: modelRef,
			errorDialog,
			err: '',
			workTypes,
			prefectures,
			bt,
			addressLoading: false,
			totalLoading: true,
			edit: false,
			rules: {
				name: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
				furigana: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^[\u3040-\u309F]+$/.test(value)) return new Error('ひらがなで入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				personName: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
				personNameFurigana: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^[\u3040-\u309F]+$/.test(value)) return new Error('ひらがなで入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				tel: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^0[0-9]{9,10}/.test(value)) return new Error('ハイフンのない10桁または11桁の半角数字で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				postcode: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
							if (!/^[0-9]{7}/.test(value)) return new Error('ハイフンのない7桁の半角数字で入力してください')
						},
						trigger: ['input', 'blur'],
					},
				],
				addressDetails: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (!value) return new Error('必須の項目です')
						},
						trigger: ['input', 'blur'],
					},
				],
			},
		}
	},
	mounted: function () {
		document.title = '病院情報入力 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				const data = await api.get('/v1/hospital/my')
				this.totalLoading = false
				if (data.name) this.edit = true
				this.model = data
			} catch (e: any) {
				this.totalLoading = false
				console.log(e)
			}
		},
		postcodeToAddress: async function () {
			try {
				console.log('fired')
				const value = this.model.postcode
				if (!/^[0-9]{7}/.test(value)) return false
				this.addressLoading = true
				const data = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`)
				const raw = data.data
				if (raw.message) return false
				const r = raw.results[0]
				this.model.addressDetails = `${r.address1}${r.address2}${r.address3}`
				this.addressLoading = false
			} catch (e: any) {
				console.error(e)
			}
		},
		post: async function () {
			const param: any = this.model
			delete param.postcode
			if (param.status) delete param.status
			if (!param.type) return this.errorDialog({ message: '「業種」は必須項目です。' }, () => true)
			try {
				const data = await api.post('/v1/hospital/setup', param)
				this.$router.push('/hospital/apply')
			} catch (e: any) {
				this.errorDialog(e, () => true)
				console.log(this.err)
				console.error(e)
			}
		},
	},
})
